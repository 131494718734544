@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply transition-colors duration-200
}

:root {
  --foreground-rgb: 7,7,13;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 7,7,13;
    --background-end-rgb: 186, 219, 218;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: black
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(0deg, rgba(7,7,13,1) 10%, rgba(186,219,218,1) 50%, rgba(7,7,13,1) 90%);
  margin: auto;
  width: 100%;
  height: 100%;
}

main {
  color: rgb(186, 219, 218);
}

main header {
  color: #000;
}

html,
body {
  overflow: hidden;
  max-width: 1378px;
  height: 100vh;
}
#__next {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}


.ant-btn-primary {
  background: #BADBDA !important;
  color: rgb(var(--foreground-rgb)) !important;
}
.ant-btn-dangerous, .lk-disconnect-button {
  background: #fc4129 !important;
}

.ant-popover-inner {
  background: #BADBDA !important;
}

.btn-gr {
  background: #858789 !important;
  color: #BADBDA !important;
  transition: background-color 0.3s ease;
}

.btn-gr:hover {
  background: #6b6d6f !important; /* Change this to the hover color you want */
}

.lk-button {
  color: #BADBDA !important;
}

.lk-join-button {
  background: #BADBDA !important;
  color: rgb(var(--foreground-rgb)) !important;
}

.barContainer {
  min-height: 100vh;
  background-image: linear-gradient(to left bottom, #0f3443, #005e6f, #008b90, #00baa0, #34e89e);
}

.card-body {
  max-width: 800px;
}

.percentage {
  width: 50px;
  height: 30px;
  top: -55px;
  animation: moveleft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

.arrow {
  left: 50%;
  bottom: -2px;
  height: 10px;
  width: 10px;
  transform: translateX(-50%) rotate(45deg);
}

.progress-bar {
  opacity: 0;
  animation: progress 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
}

@keyframes progress {
  0% {
    left: 0;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


:where(.css-dev-only-do-not-override-1pg9a38).ant-btn-compact-item.ant-btn-primary:not([disabled])+.ant-btn-compact-item.ant-btn-primary:not([disabled]):before {
  background: #BADBDA !important;
}